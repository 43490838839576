<template>
  <default-layout no-auth>
    <section>
      <h2 class="section-title"><span class="material-icons">password</span>パスワードをお忘れの方</h2>
      <form>
        <template v-if="tokenError">
          <div class="danger">{{tokenError}}</div>
        </template>
        <template v-else-if="updated">
          <p class="c-text">パスワードを更新しました。<br>ログイン画面から新しいパスワードでログインしてください。</p>
        </template>
        <template v-else>
          <p class="pt-2 pl-2 pb-1">
            新しいパスワードを入力してください。
          </p>
          <div class="form-inner">
            <label>パスワード</label>
            <input-password autocomplete="new-password" placeholder="" v-model="form.newPassword" :error="$error('newPassword')"/>
            <p>英字・数字をそれぞれ１文字以上含め、8文字以上入力してください。</p>
          </div>
          <div class="form-inner">
            <label>パスワード（確認用）</label>
            <input-password autocomplete="new-password" placeholder="" v-model="form.newPasswordConfirm" :error="$error('newPasswordConfirm')"/>
            <p class="c-form-ex__text">上のパスワードと同じ内容を入力してください。</p>
          </div>
        </template>
        <div class="p-2" v-if="tokenError || updated">
          <button v-button:to-login-form @click="$move('/')"></button>
        </div>
        <div class="p-2" v-else>
          <button v-button:save @click="update"></button>
        </div>
      </form>
    </section>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";

export default {
  data () {
    return {
      form: {
        token: "",
        newPassword: "",
        newPasswordConfirm: ""
      },
      tokenError: '',
      updated: false,
      error: {}
    };
  },
  created() {
    this.form.token = window.location.hash.substring(1);
    Service.checkToken(this.form.token)
        .catch(e => {
          this.tokenError = e.message;
          return false;
        });
  },
  methods: {
    update () {
      Service.update(this.form)
          .then(() => this.updated = true)
          .catch(e => this.error = e);
    }
  }
}
</script>
